import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import MainLayout from '../../../components/HOC/MainLayout/MainLayout'
import './DropEvents.scss'
import { useDispatch, useSelector } from 'react-redux'
import useDebounce from '../../../hooks/useDebounce'
import { bulkCreateNfts, getAllNftList, bulkUpdateNfts } from '../../../redux/_actions/nft.action'
import DraftsTab from './DraftsTab'
import ActionsBulk from 'components/common/ActionsBulk/ActionsBulk'
const { TabPane } = Tabs

const DropEvents = () => {
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [order, setOrder] = useState('')
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(1)
  const [selectedTab, setSelectedTab] = useState('')

  const nftList = useSelector((state) => state?.nft?.nftList)
  const totalNft = useSelector((state) => state?.nft?.totalNft)

  const debaounceWinerySearch = useDebounce(search, 500)

  const dispatch = useDispatch()

  useEffect(() => {
    if (localStorage.getItem('activeTab')) {
      setSelectedTab(localStorage.getItem('activeTab'))
    } else {
      localStorage.setItem('activeTab', 'DRAFT')
      setSelectedTab('DRAFT')
    }
  }, [])

  useEffect(() => {
    if (selectedTab === 'DRAFT') {
      getAllNft('DRAFT,EMPTY_DRAFT')
    }
    if (selectedTab === 'CURRENT') {
      getAllNft('APPROVED,LIVE,MINTED,EMPTY_APPROVED')
    }
    if (selectedTab === 'COMPLETED') {
      getAllNft('ENDED,SOLD_OUT')
    }
  }, [offset, limit, status, order, debaounceWinerySearch, selectedTab])

  const getAllNft = async (dropStatuses) => {
    let dataFields = {
      limit: limit,
      offset: offset,
      searchKey: search,
      status: status,
      order: order,
      event_status: 'DRAFT',
      dropStatus: dropStatuses,
    }

    if (selectedTab !== '') {
      dispatch(getAllNftList(dataFields)).then((res) => {})
    }
  }

  const bulkUpdateNftsContent = {
    trigger: { text: 'Update Bulk' },
    body: { title: 'Bulk Updation' },
  }

  return (
    <MainLayout>
      <div className='Dropevent'>
        <h3 className='common-sub-heading mb30'>Drop Events</h3>
        <div className='createBulWrapper'>
          <ActionsBulk actionFn={bulkCreateNfts} />
          <ActionsBulk actionFn={bulkUpdateNfts} content={bulkUpdateNftsContent} />
        </div>
      </div>
      <Tabs
        className='exclusive__tabs'
        defaultActiveKey='DRAFT'
        onChange={(e) => {
          localStorage.setItem('activeTab', e)
          setSelectedTab(e)
        }}
      >
        <TabPane tab='Drafts' key='DRAFT'>
          <DraftsTab
            search={search}
            setSearch={setSearch}
            status={status}
            setStatus={setStatus}
            limit={limit}
            setLimit={setLimit}
            offset={offset}
            setOffset={setOffset}
            order={order}
            setOrder={setOrder}
            nftList={nftList}
            totalNft={totalNft}
            selectedTab={selectedTab}
            getAllNft={getAllNft}
          />
        </TabPane>
        <TabPane tab='Current' key='CURRENT'>
          <DraftsTab
            search={search}
            setSearch={setSearch}
            status={status}
            setStatus={setStatus}
            limit={limit}
            setLimit={setLimit}
            offset={offset}
            setOffset={setOffset}
            order={order}
            setOrder={setOrder}
            nftList={nftList}
            totalNft={totalNft}
            selectedTab={selectedTab}
            getAllNft={getAllNft}
          />
        </TabPane>
        <TabPane tab='Ended' key='COMPLETED'>
          <DraftsTab
            search={search}
            setSearch={setSearch}
            status={status}
            setStatus={setStatus}
            limit={limit}
            setLimit={setLimit}
            offset={offset}
            setOffset={setOffset}
            order={order}
            setOrder={setOrder}
            nftList={nftList}
            totalNft={totalNft}
            selectedTab={selectedTab}
            getAllNft={getAllNft}
          />
        </TabPane>
      </Tabs>
    </MainLayout>
  )
}

export default DropEvents
